
























































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { api } from "@/api";
import { appName } from "@/env";
import { readLoginError } from "@/store/main/getters";
import {
  dispatchCheckAddress,
  dispatchGetServiceAddress,
  dispatchSet20,
} from "@/store/main/actions";
import VuetifyGoogleAutocomplete from "./VuetifyGoogleAutocomplete.vue";
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import Progress from "./Progress.vue";
import _ from "lodash";
import { formatAddress } from "../utils";
import Loading from "./Loading.vue";

@Component({
  components: { VuetifyGoogleAutocomplete, Header, Footer, Progress, Loading },
  props: {
    editInfo: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
    },
  },
})
export default class Feasibility extends Vue {
  public valid = true;
  public appName = appName;
  public unit = "";
  public address = "";
  public place = "";
  public placeId = "";
  public addressInfo = "";
  public addressData = "";
  public showLoading = false;
  public tip = "";

  $refs!: Vue["$refs"] & {
    map: typeof VuetifyGoogleAutocomplete;
  };
  public async mounted() {
    await dispatchSet20(this.$store, {});
    await dispatchGetServiceAddress(this.$store, (response) => {
      if (_.get(response, "address1", null)) {
        this.unit = _.get(response, "unit", "");
        this.address = formatAddress(response);
        const googleAddress = _.get(response, "google_address_json", null);
        const place = _.get(response, "google_place_json", null);
        const placeId = _.get(response, "google_place_id", null);
        if (googleAddress) {
          this.addressData = JSON.parse(googleAddress);
        }
        if (place) {
          this.place = JSON.parse(place);
        }
        if (placeId) {
          this.placeId = placeId;
        }
        this.$refs.map.update(this.address);
      }
    });
  }
  public get loginError() {
    return readLoginError(this.$store);
  }

  public validate() {
    if (this.address) {
      const addresses = this.address.split(", ");
      if (addresses.length < 3) {
        this.tip = "Please select your address from the address selection menu";
      } else {
        this.tip = "";
      }
    }
    return !this.tip && !!this.address && !!this.unit;
  }

  public getAddressData(addressData, placeResultData, id) {
    this.addressData = addressData;
    this.place = placeResultData;
    this.placeId = id;
    this.addressInfo = `Address Detail: ${JSON.stringify(
      addressData
    )} ${JSON.stringify(placeResultData)} ${id}`;
  }

  public updateAddress(newAddress) {
    this.address = newAddress;
  }
  public submit() {
    this.showLoading = true;
    dispatchCheckAddress(this.$store, {
      unit: this.unit,
      address: this.address,
      addressData: `${JSON.stringify(this.addressData)}`,
      placeData: `${JSON.stringify(this.place)}`,
      placeId: `${JSON.stringify(this.placeId)}`,
    }).catch((error) => {
      this.showLoading = false;
      this.tip = "Please select your address from the address selection menu";
    });
  }
}
