var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[(!_vm.$props.editInfo)?_c('div',[_c('Header',{attrs:{"progress":"2"}})],1):_vm._e(),_c('v-container',{staticStyle:{"background-color":"white"},attrs:{"fluid":""}},[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm8":"","md8":""}},[_c('br'),(!_vm.$props.editInfo)?_c('div',[_c('v-row',[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('div',{staticClass:"FeasibilityTitle"},[_c('v-spacer'),_vm._v(" Verify Your Service Location "),_c('v-spacer')],1)])],1),_c('v-row',[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('div',{staticClass:"FeasibilityText"},[_vm._v(" To get started, enter your building name. ")])])],1)],1):_vm._e(),[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[(!!this.addressData)?_c('div',{staticClass:"MapBorder"},[_c('GmapMap',{staticStyle:{"width":"100%","height":"300px"},attrs:{"center":{
                      lat: _vm.addressData.latitude,
                      lng: _vm.addressData.longitude,
                    },"zoom":18,"map-type-id":"terrain"}},_vm._l(([
                        {
                          position: {
                            lat: _vm.addressData.latitude,
                            lng: _vm.addressData.longitude,
                          },
                        } ]),function(m,index){return _c('GmapMarker',{key:index,attrs:{"position":m.position,"clickable":true,"draggable":false}})}),1)],1):_vm._e()])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3","sm":"3","md":"3"}},[_c('v-textarea',{attrs:{"label":"Unit # / Housing Lot #","type":"unit","rows":"1","no-resize":"","requred":"","hint":"Type 'NA' if not applicable"},model:{value:(_vm.unit),callback:function ($$v) {_vm.unit=$$v},expression:"unit"}})],1),_c('v-col',{attrs:{"cols":"9","sm":"9","md":"9"}},[_c('vuetify-google-autocomplete',{ref:"map",attrs:{"id":"map","error-messages":_vm.errors.collect('address'),"required":"","data-vv-name":"address","country":"ph","disabled":false,"classname":"form-control","placeholder":"Please type your building name","placeName":"true"},on:{"placechanged":_vm.getAddressData,"change":_vm.updateAddress}}),_c('font',{attrs:{"size":"2","color":"red"}},[_vm._v(_vm._s(this.tip))])],1)],1)],1)],_c('v-spacer'),_c('v-layout',{staticStyle:{"margin-top":"30px"},attrs:{"align-center":"","justify-center":""}},[_c('v-btn',{staticClass:"green white--text",attrs:{"disabled":!_vm.validate(),"x-large":""},on:{"click":_vm.submit}},[_vm._v(" Continue > ")])],1),_c('Loading',{attrs:{"message":"Checking Service Address Feasibility Information...","showDialog":_vm.showLoading}}),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br')],2)],1)],1),(!_vm.$props.editInfo)?_c('div',[_c('Footer')],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }